

  import '/var/www/myapp/app/webpacker/images/cerpe-logo.png';

  import '/var/www/myapp/app/webpacker/images/prozital-logo.png';

  import '/var/www/myapp/app/webpacker/images/prozital.ico';

  import '/var/www/myapp/app/webpacker/images/igenomix-logo.png';

  import '/var/www/myapp/app/webpacker/images/fav-icon.png';

  import '/var/www/myapp/app/webpacker/images/soar/Plameet3.png';

  import '/var/www/myapp/app/webpacker/images/fav-icon-2.png';

  import '/var/www/myapp/app/webpacker/images/home/dashboard.png';

  import '/var/www/myapp/app/webpacker/images/home/pattern-1.svg';

  import '/var/www/myapp/app/webpacker/images/home/macbook.svg';

  import '/var/www/myapp/app/webpacker/images/home/exams.png';

  import '/var/www/myapp/app/webpacker/images/home/new-attendance.png';

  import '/var/www/myapp/app/webpacker/images/home/orders.png';

  import '/var/www/myapp/app/webpacker/images/prozital-logo-white.png';
